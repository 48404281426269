.app {
  width: 500px;
}

.game-header {
  display: flex;
  justify-content: space-between;
}

.game-header .title {
  font-size: 80px;
  margin: 0;
}

.game-header .score-container {
  position: relative;
  background: #bbada0;
  padding: 15px 25px;
  font-size: 25px;
  height: 25px;
  line-height: 47px;
  font-weight: bold;
  border-radius: 3px;
  color: white;
  margin-top: 8px;
}

.game-header .score-container .score-addition {
  position: absolute;
  right: 30px;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: rgba(119, 110, 101, 0.9);
  z-index: 100;
  animation: move-up 600ms ease-in;
  animation-fill-mode: both;
}

.game-header .score-container:after {
  position: absolute;
  width: 100%;
  top: 10px;
  left: 0;
  content: "Score";
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #eee4da;
}

.game-intro .subtitle {
  font-size: 1em;
  margin: 0;
}

.game-intro .restart-button {
  background: #8f7a66;
  border-radius: 3px;
  padding: 0 20px;
  border: none;
  color: #f9f6f2;
  height: 40px;
  line-height: 42px;
  display: block;
  text-align: center;
  float: right;
  font-size: 18px;
  outline: none;
  cursor: pointer;
}

.game-container {
  position: relative;
  background: #BBADA0;
  border-radius: 6px;
  margin-top: 40px;
  padding: 15px;
}

.game-container .game-message {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(238, 228, 218, .5);
  z-index: 100;
  text-align: center;
  animation: fade-in 800ms ease 1200ms;
  animation-fill-mode: both;
}

.game-container .game-message.game-won {
  background: rgba(237, 194, 46, .5);
  color: #f9f6f2;
}

.game-container .game-message p {
  font-size: 60px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  margin-top: 222px;
}

.game-container .game-message .actions {
  display: block;
  margin-top: 59px;
}

.game-container .game-message .retry-button {
  font-size: 18px;
  display: inline-block;
  background: #8f7a66;
  border: none;
  border-radius: 3px;
  padding: 0 20px;
  color: #f9f6f2;
  height: 40px;
  line-height: 42px;
  outline: none;
  cursor: pointer;
}

.important {
  text-transform: uppercase;
}